// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

export const RFEVIcon = ({ isHover }) => {
  const cls1 = css`
    fill: ${isHover ? "#ffffff" : "#8991f7"};
  `
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 619.49 584.03"
    >
      <defs></defs>
      <title>RFEV</title>
      <g id="RFEV">
        <g id="Crown">
          <polygon
            css={cls1}
            points="326.27 6.33 326.27 0 318.28 0 318.28 6.33 311.94 6.33 311.94 14.33 318.28 14.33 318.28 20.67 326.27 20.67 326.27 14.33 332.59 14.33 332.59 6.33 326.27 6.33"
          />
          <path
            css={cls1}
            d="M271.24,75.21c-8.52,8.79,1,10.84,1,10.84,8.17-8.42,50-8.56,50-8.56s41.83.14,50,8.56c0,0,9.54-2,1-10.84,0,0,15.29-14.58,15.29-24.19S381.36,28,370,28s-14.32,3.65-14.32,3.65-9.3-8.56-18.1-8.56S327.29,27,327.29,27a8.3,8.3,0,0,0-5-1.29,8.43,8.43,0,0,0-5,1.29s-1.5-3.89-10.32-3.89-18.07,8.56-18.07,8.56S285.8,28,274.52,28s-18.58,13.43-18.58,23S271.24,75.21,271.24,75.21Zm85.09-17.95c0-6.14,2.64-9.15,4.06-10.62a6.83,6.83,0,0,1,8.68-.49s7.31,7.35-2.79,17.79-21,6.06-21,6.06S356.33,64.74,356.33,57.26ZM330.48,39.45s13.18-6.14,13.18,6.09-13.18,18-13.18,18Zm-16.7,0V63.58s-13.2-5.82-13.2-18S313.78,39.45,313.78,39.45Zm-38.64,6.7a6.82,6.82,0,0,1,8.69.49c1.46,1.47,4.07,4.48,4.07,10.62C287.9,64.74,299,70,299,70S288,74.33,278,63.94,275.14,46.15,275.14,46.15Z"
          />
          <path
            css={cls1}
            d="M368,97.29a3.5,3.5,0,0,0,2.18-6.23c-10.69-8.55-46.42-8.65-47.93-8.65s-37.88.1-48,8.71a3.5,3.5,0,0,0,4.52,5.35c5.75-4.87,28.91-7.06,43.53-7.06,14.39,0,37.42,2.21,43.57,7.11A3.44,3.44,0,0,0,368,97.29Z"
          />
        </g>
        <g id="BdrIzq">
          <polygon
            css={cls1}
            points="238.28 227.72 95.33 227.72 53.44 293.68 195.11 293.68 238.28 227.72"
          />
          <polygon
            css={cls1}
            points="190.06 301.4 48.54 301.4 14 356.15 154.23 356.15 190.06 301.4"
          />
          <polygon
            css={cls1}
            points="290.32 162.41 252.63 220 250.11 223.86 247.58 227.72 204.41 293.68 201.89 297.54 199.36 301.4 161.01 360.01 158.49 363.87 133.59 401.91 313.86 401.91 313.86 126.44 292.84 158.56 290.32 162.41"
          />
          <polygon
            css={cls1}
            points="278.49 166.27 134.14 166.27 100.21 220 243.33 220 278.49 166.27"
          />
        </g>
        <g id="BdrDch">
          <path
            css={cls1}
            d="M571.4,301.56H468.25A195.13,195.13,0,0,1,464,356.15H605.55Z"
          />
          <path
            css={cls1}
            d="M566.59,293.85l-41.12-66H441.94a133.52,133.52,0,0,1,15.32,26.86,143.87,143.87,0,0,1,10.23,39.11Z"
          />
          <path
            css={cls1}
            d="M460,297.7c-.13-1.28-.27-2.56-.42-3.85a134.18,134.18,0,0,0-9.49-36.12,125.51,125.51,0,0,0-17.95-29.85c-1-1.29-2.06-2.59-3.16-3.85s-2.34-2.59-3.54-3.86c-18.73-20-44.78-35.8-78-47.31-.56-.2-1.1-.41-1.66-.6,2.88,5.23,6.73,12.65,10.94,21.81,2.74,6,5.63,12.67,8.51,20,18.06,46.16,35.51,117.58,10.82,187.8h64.56a228.64,228.64,0,0,0,14-40.55c.42-1.69.82-3.43,1.21-5.2s.76-3.53,1.12-5.34a187.78,187.78,0,0,0,3.36-49.26C460.2,300.28,460.1,299,460,297.7Z"
          />
          <path
            css={cls1}
            d="M435.86,220.17h84.79l-33.72-53.9H352.15C388.4,179.16,416.39,197.21,435.86,220.17Z"
          />
        </g>
        <g id="Olympic">
          <path
            css={cls1}
            d="M245.18,571.53a45,45,0,0,1-44.35-37.46c-.05-.46-.13-.92-.19-1.4s-.16-.95-.2-1.41c-.15-1.54-.25-3.13-.25-4.7a45.94,45.94,0,0,1,1-9.31c.14-.62.29-1.23.4-1.83s.36-1.27.56-1.93a45.07,45.07,0,0,1,18.74-24.82,40.38,40.38,0,0,0,1.59-11.27c0-1.22-.06-2.41-.17-3.62a57.61,57.61,0,0,0-34.07,45.09c0,.45-.12,1-.18,1.46s-.06.94-.14,1.42c-.11,1.57-.2,3.17-.2,4.81a56.88,56.88,0,0,0,.55,7.78c.07.48.11.95.21,1.4s.15.92.22,1.39a57.54,57.54,0,0,0,113.1,0,61,61,0,0,1-12.19-3.06A45.06,45.06,0,0,1,245.18,571.53Z"
          />
          <path
            css={cls1}
            d="M245.18,481.52a48.28,48.28,0,0,1,4.88.25c.48.06,1,.08,1.45.18s.93.17,1.36.23a45.7,45.7,0,0,1,13.31,4.51l1.61.9c.58.35,1.16.71,1.71,1.08a44.93,44.93,0,0,1,18.79,24.82,42.34,42.34,0,0,0,13.93,5.38,57.6,57.6,0,0,0-34.07-45.06,12.08,12.08,0,0,0-1.37-.57,11,11,0,0,0-1.33-.56,54,54,0,0,0-12.21-3.08c-.47-.07-.91-.15-1.36-.19s-1-.11-1.41-.16c-1.75-.14-3.5-.23-5.29-.23s-3.49.09-5.25.23a59.91,59.91,0,0,1,.59,8.15c0,1.5-.11,2.92-.19,4.37C241.92,481.61,243.56,481.52,245.18,481.52Z"
          />
          <path
            css={cls1}
            d="M185.09,522.13a36.32,36.32,0,0,1-4.92.3,45,45,0,1,1,44.38-52.58c.07.49.17,1,.22,1.44a12.57,12.57,0,0,1,.2,1.39,45.48,45.48,0,0,1-.73,14c-.13.61-.25,1.22-.42,1.83s-.37,1.29-.57,1.93a44.84,44.84,0,0,1-18.75,24.81,42.87,42.87,0,0,0-1.53,11.3,35.66,35.66,0,0,0,.19,3.59,57.5,57.5,0,0,0,34-45.08c.07-.49.13-.94.16-1.44s.13-1,.15-1.45c.11-1.57.18-3.12.18-4.78a60.83,60.83,0,0,0-.51-7.8c-.06-.49-.13-1-.2-1.41s-.16-.91-.24-1.34a57.51,57.51,0,1,0-56.57,68.08c1.82,0,3.57-.11,5.28-.24a61.33,61.33,0,0,1-.53-8.13C184.92,525.06,185,523.61,185.09,522.13Z"
          />
          <path
            css={cls1}
            d="M375.22,571.53a45,45,0,0,1-44.38-37.46c-.06-.46-.17-.92-.2-1.4s-.12-.95-.19-1.41a45.79,45.79,0,0,1-.25-4.7,46.66,46.66,0,0,1,.95-9.31c.15-.62.29-1.23.44-1.83s.36-1.27.53-1.93a44.8,44.8,0,0,1,18.82-24.82,44.41,44.41,0,0,0,1.5-11.27c0-1.22-.09-2.41-.17-3.62a57.62,57.62,0,0,0-34.06,45.09c0,.45-.09,1-.16,1.46s-.08.94-.15,1.42c-.13,1.57-.21,3.17-.21,4.81a60.25,60.25,0,0,0,.54,7.78c.06.48.14.95.22,1.4a11.74,11.74,0,0,0,.27,1.39,57.5,57.5,0,0,0,113,0,60.78,60.78,0,0,1-12.17-3.06A45,45,0,0,1,375.22,571.53Z"
          />
          <path
            css={cls1}
            d="M375.22,481.52c1.63,0,3.25.09,4.89.25.45.06.93.08,1.37.18s1,.17,1.42.23a45.15,45.15,0,0,1,13.27,4.51c.57.3,1.09.61,1.64.9l1.71,1.08a44.88,44.88,0,0,1,18.76,24.82,42.77,42.77,0,0,0,13.93,5.38,57.59,57.59,0,0,0-34-45.06,1388.62,1388.62,0,0,0-2.72-1.13,53.56,53.56,0,0,0-12.27-3.08c-.4-.07-.89-.15-1.33-.19s-.93-.11-1.39-.16c-1.74-.14-3.47-.23-5.25-.23s-3.53.09-5.27.23a63.27,63.27,0,0,1,.55,8.15c0,1.5-.05,2.92-.14,4.37A48.65,48.65,0,0,1,375.22,481.52Z"
          />
          <path
            css={cls1}
            d="M315.09,522.17a46.11,46.11,0,0,1-9.78,0,7.06,7.06,0,0,0-1.4-.17c-.47-.07-.9-.16-1.4-.25a44.5,44.5,0,0,1-13.23-4.5,16.56,16.56,0,0,1-1.66-.93,17,17,0,0,1-1.73-1.06,45.36,45.36,0,0,1-18.8-24.81,40.77,40.77,0,0,0-13.89-5.38,57.54,57.54,0,0,0,34.09,45.08c.42.18.89.41,1.29.57s.91.36,1.41.54a56.7,56.7,0,0,0,12.17,3.08c.48.09.94.15,1.43.21s.89.08,1.37.14c1.71.13,3.5.24,5.26.24s3.54-.11,5.24-.24a59.69,59.69,0,0,1-.54-8.13C314.92,525.06,315,523.61,315.09,522.17Z"
          />
          <path
            css={cls1}
            d="M353.82,488.52c-.15.64-.36,1.29-.52,1.93a45.17,45.17,0,0,1-18.78,24.81,42.87,42.87,0,0,0-1.53,11.3,32.94,32.94,0,0,0,.18,3.59,57.63,57.63,0,0,0,34.06-45.08,9.87,9.87,0,0,0,.12-1.44c.07-.45.13-1,.18-1.45.09-1.57.16-3.12.16-4.78a54.36,54.36,0,0,0-.53-7.8,10.64,10.64,0,0,0-.19-1.41,11.13,11.13,0,0,0-.24-1.34,57.49,57.49,0,0,0-113,0,58.89,58.89,0,0,1,12.14,3,45,45,0,0,1,88.75,0c.07.49.11,1,.22,1.44s.11.91.17,1.39c.14,1.53.23,3.14.23,4.72a44.83,44.83,0,0,1-.95,9.29C354.11,487.3,354,487.91,353.82,488.52Z"
          />
          <path
            css={cls1}
            d="M440.24,419.91a57.52,57.52,0,0,0-56.56,46.94,58.78,58.78,0,0,1,12.18,3,45,45,0,1,1,44.38,52.58,35.73,35.73,0,0,1-4.9-.3,13,13,0,0,0-1.4-.13c-.5-.07-.93-.16-1.42-.25a44.53,44.53,0,0,1-13.25-4.5c-.55-.31-1.14-.57-1.66-.93a19.13,19.13,0,0,1-1.7-1.06,45,45,0,0,1-18.79-24.81,40.9,40.9,0,0,0-13.93-5.38,57.68,57.68,0,0,0,34.11,45.08c.43.18.89.41,1.31.57l1.36.54a57.36,57.36,0,0,0,12.21,3.08c.45.09.92.15,1.39.21s.92.1,1.39.14c1.73.13,3.5.24,5.28.24a57.51,57.51,0,1,0,0-115Z"
          />
        </g>
      </g>
    </svg>
  )
}
