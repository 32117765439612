const WidgetData = {
    PageLink: [
       /*  {
            id: 1,
            name: 'About',
            url: '#',
        },
        {
            id: 2,
            name: 'Blog',
            url: '#',
        },
        {
            id: 3,
            name: 'Talent Check',
            url: '#',
        },
        {
            id: 4,
            name: 'Career',
            url: '#',
        }, */
        {
            id: 5,
            name: 'Contact',
            url: '#',
        },
    ],
    Work: [
        {
            id: 1,
            name: 'Our Clients',
            url: '#'
        },
        {
            id: 2,
            name: 'Skills',
            url: '#'
        },
        {
            id: 3,
            name: 'Consultants',
            url: '#'
        },
    ],
    Legal: [
        {
            id: 1,
            name: 'Privacidad',
            url: '/privacidad'
        },
        {
            id: 2,
            name: 'Aviso Legal',
            url: 'legal'
        },
        /* {
            id: 3,
            name: 'Advertise',
            url: '#'
        }, */
    ],
    Social: [
        {
            id: 1,
            url: 'https://www.facebook.com/esailing.espana.3',
            icon: 'fa fa-facebook',
        },
        {
            id: 2,
            url: 'https://twitter.com/',
            icon: 'fa fa-twitter',
        },
        {
            id: 3,
            url: 'https://youtu.be/cfrVVgT_qvQ',
            icon: 'fa fa-youtube-play',
        },
       /*  {
            id: 4,
            url: 'https://www.pinterest.com/',
            icon: 'fa fa-pinterest',
        }, */
    ],
    PageList: [
        {
            id: 1,
            name: 'Privacy Policy',
            url: '#',
        },
        {
            id: 2,
            name: 'About',
            url: '#',
        },
        {
            id: 3,
            name: 'FAQ',
            url: '#',
        },
        {
            id: 4,
            name: 'Contact Support',
            url: '#',
        },
    ],
}
export default WidgetData;